<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img :src="require('@/assets/logo.png')" class="center mb-4" height="60" contain></v-img>
        <v-card outlined class="pa-6 ma-auto" max-width="360px" elevation="24">
          <h1 class="text-center">Login</h1>
          <form>
            <v-text-field v-model="username" :error-messages="usernameErrors" append-icon="mdi-account" label="Username" required @input="$v.username.$touch()" @blur="$v.username.$touch()"></v-text-field>
            <v-text-field label="Password" v-model="password" :error-messages="passwordErrors" :type="show1 ? 'text' : 'password'" required @input="$v.password.$touch()" @blur="$v.password.$touch()" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" v-on:keyup.enter="submit"></v-text-field>

            <v-btn class="mr-4 my-2" @click="submit" color="primary" block> Login </v-btn>
            <v-btn class="mr-4 my-2" @click="loginVia365()" color="blue" dark block prepend-icon="mdi-windows"> Login with Microsoft Account </v-btn>

            <v-divider class="my-3"></v-divider>
            <v-btn class="mr-4 my-2" block :to="{ name: 'Forgot' }"> Forgot ? </v-btn> <v-btn class="mr-4 my-2" text block :to="{ name: 'Register' }"> Register </v-btn>
          </form>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="cpdialog" persistent hide-overlay :overlay="false" max-width="500px" transition="dialog-transition">
      <login-change-password :username="username" :password="password" @callback="submit2" @cancel="cpdialog = false"></login-change-password>
    </v-dialog>
  </v-container>
</template>
<script>
import config from "@/constants/config";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import LoginChangePassword from "../components/LoginChangePassword.vue";

export default {
  components: { LoginChangePassword },

  mixins: [validationMixin],

  validations: {
    username: { required },
    password: { required },
  },

  data: () => ({
    username: "",
    password: "",
    show1: null,
    overlay: false,
    modules: [],
    redirectUrl: "/",
    cpdialog: false,
  }),

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      this.cpdialog = false;

      this.$axios
        .post("auth/login", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == "success") {
            var mustChangePassword = res.data.data.must_change_password;
            if (mustChangePassword == true) {
              this.cpdialog = true;
              return;
            }

            this.$store.state.isLoggedIn = true;
            this.storeData(res.data.data);
            this.redirectToDashboard();

            // this.$store.state.userMenu = res.data.data.;
            // this.$router.push("/");
            // window.location.assign("/home");
          } else {
            this.showAlert(res.data.status, res.data.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    submit2(newpassword) {
      this.password = newpassword;
      this.submit();
    },
    // async getModule() {
    //   var params = [];
    //   await this.$axios
    //     .get("auth/user-module", {
    //       params: params,
    //     })
    //     .then((res) => {
    //       var resData = res.data;
    //       this.$store.state.userModule = JSON.stringify(resData.data.user_modules)

    //       // this.$router.push("/");
    //       this.redirectToDashboard();
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         console.log("Error: ", error.response.headers);
    //       } else {
    //         console.log("Error: ", error.message);
    //       }
    //     });
    // },
    clear() {
      this.$v.$reset();
      this.username = "";
      this.password = "";
    },
    redirectToDashboard() {
      window.location.assign(this.redirectUrl);
    },
    storeData(data) {
      this.$store.state.apiToken = data.token;
      this.$store.state.loginInfo.userId = data.user_id;
      this.$store.state.loginInfo.username = data.username;
      this.$store.state.loginInfo.firstName = data.first_name;
      this.$store.state.loginInfo.lastName = data.last_name;
      this.$store.state.loginInfo.profileImage = data.user_img;
      this.$store.state.loginInfo.email = data.email;
      this.$store.state.loginInfo.userType = data.user_type;
      this.$store.state.loginInfo.userLevel = data.user_level;
    },
    loginVia365() {
      var loginUrl = config.apiUrl + "msal/signin";
      window.location.replace(loginUrl);
    },
  },

  mounted() {
    if (typeof this.$route.query.redirect !== "undefined") {
      this.redirectUrl = this.$route.query.redirect;
    } else {
      this.redirectUrl = "/";
    }

    if (this.redirectUrl.substring(0, 1) !== "/") {
      this.redirectUrl = "/" + this.redirectUrl;
    }
  },
};
</script>
