<template>
  <!-- <v-dialog v-model="dialog" persistent hide-overlay :overlay="false" max-width="500px" transition="dialog-transition"> -->
  <v-card>
    <v-card-title>Change your password </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-4">
      <p>First login? change your standard password!</p>
      <v-text-field v-model="newpassword" :error-messages="newpasswordErrors" label="New Password" type="password" clearable autofocus></v-text-field>
      <v-text-field v-model="renewpassword" :error-messages="renewpasswordErrors" label="Re-enter password" type="password" clearable></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" @click="submit()">Update</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('cancel');">Cancel</v-btn>
    </v-card-actions>
  </v-card>

  <!-- </v-dialog> -->
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    newpassword: { required },
    renewpassword: { required },
  },

  props: ["username", "password"],

  data() {
    return {
      newpassword: "",
      renewpassword: "",
    };
  },

  computed: {
    newpasswordErrors() {
      const errors = [];
      if (!this.$v.newpassword.$dirty) return errors;
      !this.$v.newpassword.required && errors.push("New Password is required");
      return errors;
    },
    renewpasswordErrors() {
      const errors = [];
      if (!this.$v.renewpassword.$dirty) return errors;
      !this.$v.renewpassword.required && errors.push("Re-enter Password is required");

      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.newpassword !== this.renewpassword) {
        this.showAlert("error", "Password do not match");
        return;
      }

      this.showLoadingOverlay(true);

      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      formData.append("newpassword", this.newpassword);

      this.$axios
        .post("auth/flcp", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          if (res.data.status == "success") {
            this.$emit("callback", this.newpassword);
          } else {
            this.showAlert(res.data.status, res.data.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
};
</script>
